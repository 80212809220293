import { useState } from "react";
import * as z from "zod";
import { useForm } from "react-hook-form";
import Image from "next/image";
import { zodResolver } from "@hookform/resolvers/zod";

import FieldText from "components/Form/FieldText";
import Button from "components/Button";
import Modal from "components/Modal";
import { useAuthSendPasswordResetApi } from "./authApi";
import { GoogleLoginButton } from "./AuthSocialButtonNew";
import { useIsSubdomain, useIsTenant } from "lib/host";
import useTranslation from "next-translate/useTranslation";

export const ForgotSchema = z.object({
  email: z.string().email({ message: "Alamat Email tidak valid" }),
});

type FormData = z.infer<typeof ForgotSchema>;

export const AuthVerifyReset: React.FC<{
  isShow: boolean;
  onClose(): void;
  currentEmail?: string;
}> = ({ isShow, onClose, currentEmail }) => {
  const { t } = useTranslation("common")
  const [isShowSuccess, showSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(1);
  const isB2B = useIsTenant() || useIsSubdomain();

  const hookForm = useForm<FormData>({
    resolver: zodResolver(ForgotSchema),
  });

  const { handleSubmit, reset } = hookForm;

  const authSendPasswordReset = useAuthSendPasswordResetApi();

  /**
   * onSubmit
   */
  const onSubmit = () => {
    setIsLoading(true);

    authSendPasswordReset.doFetch(
      {
        email: currentEmail,
      },
      {
        onSuccess(res) {
          if (res) {
            setIsLoading(false);
            setStep(step + 1);
          }
        },
      }
    );
    reset();
  };

  return (
    <Modal
      title={step == 1 ? "" : "Cek Emailmu"}
      isShow={isShow}
      onClose={onClose}
    >
      <div className="flex items-center pt-24">
        {step == 1 ? null : (
          <Image
            src={"/images/icon-credential-email.png"}
            alt=""
            width={58}
            height={58}
          />
        )}
        <p className="text-b2 text-neutral-70 ml-16 font-light">
          {step == 1 ? (
            <>
              Hi, kami baru saja menerapkan sistem keamanan yang baru. Demi
              kelancaran aksesmu, yuk{" "}
              <strong>masuk dengan Google dengan email yang sama.</strong>
            </>
          ) : (
            "Kami telah mengirimkan permintaan reset password ke emailmu. Silahkan melakukan perubahan password."
          )}
        </p>
      </div>
      <div className="mt-24">
        {step == 1 ? (
          <div>
            {!isB2B && <GoogleLoginButton />}

            <div className="p-8 text-center">{t("or")}</div>

            <Button
              size="large"
              variant="secondary"
              className="border-0"
              isFull
              onClick={onSubmit}
              state={isLoading ? "loading" : "active"}
            >
              Reset Password
            </Button>
          </div>
        ) : (
          <Button
            size="large"
            isFull
            onClick={() => {
              showSuccess(false);
              onClose();
            }}
          >
            Saya Mengerti
          </Button>
        )}
      </div>
    </Modal>
  );
};
