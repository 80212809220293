import { useState } from "react";

import IconEye from "../Icon/IconEye";
import IconEyeSlash from "../Icon/IconEyeSlash";
import Field, { Props as BaseProps } from "./Field";
import InputBase, { style as inputBaseStyle } from "./InputBase";

type Props = BaseProps & {
  defaultValue?: any;
  placeholder?: string;
  inputStyle?: string;
  disabled?: boolean;
};

const FormFieldPassword: React.FC<Props> = (props) => {
  const { name, validator, children, defaultValue, inputStyle, ...others } =
    props;
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Field name={name} validator={validator} {...others}>
      <InputBase
        name={name}
        defaultValue={defaultValue}
        validator={validator}
        afterInput={
          <button
            type="button"
            className="absolute right-10 top-10"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? (
              <IconEyeSlash size={20} color="#5D586C" />
            ) : (
              <IconEye size={20} color="#5D586C" />
            )}
          </button>
        }
        onRender={(field) => (
          <input
            className={`${inputBaseStyle}${inputStyle || ""}`}
            type={showPassword ? "text" : "password"}
            id={`input-${name}`}
            {...field}
            {...others}
          />
        )}
      />
    </Field>
  );
};

export default FormFieldPassword;
