import React from "react";

type Props = {
  size?: number;
  color?: string;
};

const IconEye: React.FC<Props> = ({ size = 32, color = "#3E3851" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="fill-neutral-60"
    >
      <path
        d="M17.2667 6.66683C15.5833 2.7585 12.4167 0.333496 8.99999 0.333496C5.58333 0.333496 2.41666 2.7585 0.733328 6.66683C0.687442 6.77197 0.663757 6.88545 0.663757 7.00016C0.663757 7.11488 0.687442 7.22836 0.733328 7.3335C2.41666 11.2418 5.58333 13.6668 8.99999 13.6668C12.4167 13.6668 15.5833 11.2418 17.2667 7.3335C17.3125 7.22836 17.3362 7.11488 17.3362 7.00016C17.3362 6.88545 17.3125 6.77197 17.2667 6.66683ZM8.99999 12.0002C6.35833 12.0002 3.85833 10.0918 2.41666 7.00016C3.85833 3.9085 6.35833 2.00016 8.99999 2.00016C11.6417 2.00016 14.1417 3.9085 15.5833 7.00016C14.1417 10.0918 11.6417 12.0002 8.99999 12.0002ZM8.99999 3.66683C8.34072 3.66683 7.69626 3.86233 7.14809 4.2286C6.59993 4.59487 6.17269 5.11546 5.9204 5.72455C5.6681 6.33364 5.60209 7.00386 5.73071 7.65046C5.85933 8.29707 6.1768 8.89101 6.64297 9.35719C7.10915 9.82336 7.70309 10.1408 8.34969 10.2694C8.9963 10.3981 9.66652 10.3321 10.2756 10.0798C10.8847 9.82747 11.4053 9.40023 11.7716 8.85206C12.1378 8.3039 12.3333 7.65943 12.3333 7.00016C12.3333 6.11611 11.9821 5.26826 11.357 4.64314C10.7319 4.01802 9.88405 3.66683 8.99999 3.66683ZM8.99999 8.66683C8.67036 8.66683 8.34813 8.56908 8.07404 8.38595C7.79996 8.20281 7.58634 7.94251 7.4602 7.63797C7.33405 7.33342 7.30104 6.99831 7.36535 6.67501C7.42966 6.35171 7.5884 6.05474 7.82148 5.82165C8.05457 5.58856 8.35154 5.42983 8.67484 5.36552C8.99815 5.30121 9.33326 5.33422 9.6378 5.46036C9.94234 5.58651 10.2026 5.80013 10.3858 6.07421C10.5689 6.34829 10.6667 6.67053 10.6667 7.00016C10.6667 7.44219 10.4911 7.86611 10.1785 8.17867C9.86594 8.49123 9.44202 8.66683 8.99999 8.66683Z"
        // fill={color}
      />
    </svg>
  );
};

export default IconEye;
