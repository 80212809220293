import { useEffect, useState } from "react";
import * as z from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import FieldText from "components/Form/FieldText";
import FieldPassword from "components/Form/FieldPassword";
import Button from "components/Button";
import { AuthForgotPassword } from "./AuthForgotPassword";
import { useAuthSetLogin, useRedirectPreviousPath } from "./authHooks";
import Link from "next/link";
import { trackEvent, useTrackScreen } from "lib/amplitude";
import { AuthVerifyReset } from "./AuthVerifyReset";
import { useRouter } from "next/router";
import { useAuthStore } from "./authStore";
import { useUserGetProfile } from "modules/User/userApi";
import { getCookie, setCookie } from "lib/helper";
import useTranslation from "next-translate/useTranslation";
import { IconArrow } from "components/Icon/IconArrow";
import { isBrowser } from "lib/ssr";
import {
  getSubDomain,
  isBlueBirdURL,
  isGateURL,
  isPreviewURL,
  isSubdomainBluebird,
  useIsSubdomain,
  useIsTenant,
} from "lib/host";
import { AuthSocialButtonNew } from "./AuthSocialButtonNew";
import {
  useAuthAccountApi,
  useAuthRegisterApi,
  useAuthV2LoginApi,
} from "./authApi";
import { CONST } from "lib/const";
import { useToastStore } from "components/Toast/toastStore";
import React from "react";
import { useSystemStore } from "components/PageHeader/systemStore";

export const b2cSubdomain = ["www", "localhost"];
// For Apple submission purpose (25 June 2024)
export const listSubdomainNoGoogleSignIn = ["ares-gate"];

// TODO: Check the usage since make error
// Hydration failed because the initial UI does not match what was rendered on the server.
export const AuthLoginApp: React.FC<{
  useRegister?: boolean;
  domainName?: string;
  useFieldOrganization?: boolean;
  ip_address?: string;
  userAgent?: string;
}> = ({
  useRegister = true,
  domainName = "",
  useFieldOrganization = false,
  ...props
}) => {
  const [isShowForgot, showForgot] = useState(false);
  const [isShowVerify, showVerify] = useState(false);
  const [step, setStep] = useState(1);
  const [currentLogin, setCurrentLogin] = useState({});
  const [totalErrorPwd, setTotalError] = useState(0);

  const isBlueBirdTenant =
    typeof window !== "undefined" &&
    (isBlueBirdURL(window.location.hostname) ||
      isSubdomainBluebird(window.location.hostname));
  const isPreviewTenant =
    typeof window !== "undefined" && isPreviewURL(window.location.hostname);

  const LoginSchema: any = z.object({
    username: z.string().min(1, { message: "Masukkan email/no.hp kamu" }),
    password: z.string().min(8, { message: "Kata sandi minimal 8 karakter" }),
  });

  type FormData = z.infer<typeof LoginSchema>;

  const hookLoginForm = useForm<FormData>({
    resolver: zodResolver(LoginSchema),
  });

  return (
    <div className="w-full">
      {isBlueBirdTenant && isPreviewTenant ? (
        <FormLogin
          setCurrentLogin={setCurrentLogin}
          showForgot={showForgot}
          domainName={domainName}
          showVerify={showVerify}
          setTotalError={setTotalError}
          totalErrorPwd={totalErrorPwd}
          handleUpdateStep={setStep}
        />
      ) : (
        {
          1: (
            <FormLogin
              setCurrentLogin={setCurrentLogin}
              showForgot={showForgot}
              domainName={domainName}
              showVerify={showVerify}
              setTotalError={setTotalError}
              totalErrorPwd={totalErrorPwd}
              handleUpdateStep={setStep}
            />
          ),
          2: (
            <FormRegister
              currentLogin={currentLogin}
              handleUpdateStep={setStep}
              useFieldOrganization={useFieldOrganization}
              {...props}
            />
          ),
        }[step]
      )}

      <AuthForgotPassword
        currentEmail={hookLoginForm.getValues().username}
        isShow={isShowForgot}
        onClose={() => showForgot(false)}
      />

      <AuthVerifyReset
        currentEmail={hookLoginForm.getValues().username}
        isShow={isShowVerify}
        onClose={() => showVerify(false)}
      />
    </div>
  );
};

export const FormLogin: React.FC<{
  showForgot?: any;
  setCurrentLogin?: any;
  handleUpdateStep?: any;
  domainName?: any;
  setTotalError?: any;
  totalErrorPwd?: any;
  showVerify?: any;
}> = ({
  showForgot,
  setCurrentLogin,
  handleUpdateStep,
  domainName,
  setTotalError,
  totalErrorPwd,
  showVerify,
}) => {
  const authSetLogin = useAuthSetLogin();
  const [currentStep, setCurrentStep] = useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const router = useRouter();
  const { redirect_uri } = router.query;
  const { t } = useTranslation("common");
  const authStore = useAuthStore();
  const userGetProfile = useUserGetProfile();
  const { encodedPath } = useRedirectPreviousPath();
  const { email, name } = router.query;

  const LoginSchema: any =
    currentStep == 1
      ? z.object({
          username: z.string().min(1, { message: "Masukkan email/no.hp kamu" }),
        })
      : z.object({
          username: z.string().min(1, { message: "Masukkan email/no.hp kamu" }),
          password: z
            .string()
            .min(8, { message: "Kata sandi minimal 8 karakter" }),
        });
  type FormData = z.infer<typeof LoginSchema>;
  const hookLoginForm = useForm<FormData>({
    resolver: zodResolver(LoginSchema),
  });

  const { setValue } = hookLoginForm;

  useEffect(() => {
    if (email) setValue("username", email as string);
    if (name) setValue("name", name as string);
  }, [email, name, setValue]);

  const authV2LoginApi = useAuthV2LoginApi();
  const authAccountApi = useAuthAccountApi();
  const { handleSubmit } = hookLoginForm;
  const isSubdomainTenant = useIsSubdomain();
  const isB2B = useIsTenant() || useIsSubdomain();
  const subDomain = isBrowser() ? getSubDomain(window.location.hostname) : null;
  const showToast = useToastStore((state) => state.showToast);

  const isBlueBirdTenant =
    typeof window !== "undefined" &&
    (isBlueBirdURL(window.location.hostname) ||
      isSubdomainBluebird(window.location.hostname));
  const isPreviewTenant =
    typeof window !== "undefined" && isPreviewURL(window.location.hostname);

  const onSubmit = (data: FormData) => {
    let body = { ...data };

    switch (currentStep) {
      // verify account
      case 1:
        setCurrentLogin(body);
        authAccountApi.doFetch(body, {
          onSuccess(data: any) {
            if (data.is_register) {
              if (isBlueBirdTenant) {
                setErrorMessage(
                  "*Email tidak ditemukan. Akun hanya dapat didaftarkan oleh Bluebird. Silakan hubungi HR atau admin Bluebird untuk informasi lebih lanjut"
                );
                return;
              } else if (isPreviewTenant) {
                setErrorMessage(
                  "*Email tidak ditemukan. Akun hanya dapat didaftarkan oleh goKampus. Silakan hubungi HR atau admin goKampus untuk informasi lebih lanjut"
                );
                return;
              }

              handleUpdateStep((n) => n + 1);
              // router.push({
              //   pathname: "/register",
              // });
            } else {
              setCurrentStep((p) => p + 1);
            }
          },
          onError(err) {
            if (err instanceof authAccountApi.fetcher.Error) {
              const error: any = err.getActualType();
              if ([409, 404, 403].includes((error as any)?.status)) {
                hookLoginForm.setError("username", {
                  message: error.data?.message,
                });
              }

              if (error?.status == 422) {
                Object.entries(error.data.errors).map(([key, value]) => {
                  hookLoginForm.setError(key, {
                    message: value as string,
                  });
                });
              } else if (error?.status == 429) {
                showToast({
                  type: "danger",
                  message: t("errorServer"),
                });
              }
            }
          },
        });
        break;

      // next login
      case 2:
        let origin = "business";

        let referral = !["ares", "business", "www", "localhost"].includes(
          subDomain
        )
          ? subDomain
          : "";

        if (window.location.hostname === "e-learning.bluebirdgroup.com") {
          referral = "bluebird";
        }

        body = {
          username: data.username,
          password: data.password,
          origin,
          referral,
        };

        if (redirect_uri) {
          body.redirect_uri = redirect_uri;
          localStorage.setItem("redirect_uri", redirect_uri as string);
        }

        authV2LoginApi.doFetch(body, {
          onSuccess(data: any) {
            if (data.redirect_uri) {
              localStorage.setItem("token", data.access_token);
              setCookie("token", data.access_token, 7);
              authStore.setLoading();
              userGetProfile.doFetch(
                {},
                {
                  onSuccess(res) {
                    authStore.setLogin(res.data);
                    const directURL = data.redirect_uri + "?code=" + data.code;
                    router.replace(directURL as any);
                  },
                }
              );
            } else {
              authSetLogin(
                data.access_token,
                "username",
                "signin_success_a",
                null,
                // domainName ? "domain=gokampus.com" : null
                "domain=gokampus.com"
              );
            }
          },
          onError(err) {
            if (err instanceof authV2LoginApi.fetcher.Error) {
              const error: any = err.getActualType();

              if (error.status == 401) {
                hookLoginForm.setError("password", {
                  message: error.data?.message,
                });
              } else {
                if ([409, 404, 403].includes((error as any)?.status)) {
                  hookLoginForm.setError("username", {
                    message: error.data?.message,
                  });
                }

                if (error?.status == 422) {
                  Object.entries(error.data.errors).map(([key, value]) => {
                    hookLoginForm.setError(key, {
                      message: value as string,
                    });
                  });
                } else if (error?.status == 429) {
                  showToast({
                    type: "danger",
                    message: t("errorServer"),
                  });
                }
              }
            }
          },
        });
        break;
    }
  };

  const [theme] = useSystemStore((state) => [state.theme]);

  const [isPWA, setIsPWA] = useState(false);

  useEffect(() => {
    const checkIsPWA = () => {
      return window.matchMedia('(display-mode: standalone)').matches || (window.navigator as Navigator).standalone === true;
    }

    if (checkIsPWA()) {
      setCurrentStep((p) => p + 1);
      setIsPWA(true);
    } else {
      setIsPWA(false);
    }
  }, [])

  return (
    <div className="">
      <div className="text-neutral-80">
        {isBlueBirdTenant ? (
          <p className="font-bold text-h4">{t("loginAccountNow")}</p>
        ) : (
          <>
            <p className="font-bold text-h4">{t("welcome")} goKampus</p>
            <span className="font-light text-b2">{t("loginAccountNow")}</span>
          </>
        )}
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={`mt-24 min-h-[50vh]`}
        // className={`mt-24 ${!isBlueBirdTenant && "min-h-[50vh]"}`}
      >
        <FieldText
          name="username"
          label={isBlueBirdTenant ? t("emailTitleBluebird") : t("emailTitle")}
          placeholder="Masukkan No. HP / Email"
          validator={{
            hook: hookLoginForm,
          }}
        />

        {errorMessage !== "" && (
          <p className="-mt-12 text-12 text-red-70">{errorMessage}</p>
        )}

        {currentStep === 2 && (
          <FieldPassword
            name="password"
            label={isBlueBirdTenant ? t("passTitleBluebird") : t("passTitle")}
            placeholder={t("passDesc")}
            validator={{
              hook: hookLoginForm,
            }}
          />
        )}

        <div className="mt-16">
          <div className="mb-16">
            <Button
              onClick={() => {
                trackEvent({
                  event: "signin_a",
                  property: { is_success: 0 },
                });
              }}
              type="submit"
              size="large"
              isFull
              state={
                authAccountApi.isLoading || authV2LoginApi.isLoading
                  ? "loading"
                  : errorMessage !== ""
                  ? "disabled"
                  : "active"
              }
            >
              {currentStep === 2 ? t("login") : t("next")}
            </Button>
          </div>

          {!isBlueBirdTenant && !isPreviewTenant && (
            <>
              {currentStep == 1 && (
                <>
                  {listSubdomainNoGoogleSignIn.includes(subDomain) ? (
                    <></>
                  ) : (
                    <div className="w-full flex justify-center mt-24">
                      {/* <AuthSocialButtonNew
                        trackerId="signup_google_a"
                        className="w-full"
                      /> */}
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>

        {currentStep === 2 ? (
          <button
            type="button"
            className="underline w-full font-medium text-center text-neutral-80 text-14"
            onClick={() => {
              trackEvent({ event: "signin_forgotpass_a" });
              showForgot(true);
            }}
          >
            {t("forgotPass")}
          </button>
        ) : (
          <>
            {!isBlueBirdTenant && !isPreviewTenant && (
              <div className="mt-16 text-center">
                <div className="text-b2 text-center font-light">
                  {t("haventAccount")}{" "}
                  <div className="inline underline font-medium text-neutral-80 text-14">
                    <Link
                      onClick={() => {
                        trackEvent({
                          event: "signup_redirect_a",
                        });
                      }}
                      href={{
                        pathname: "/register",
                        query: encodedPath ? { url: encodedPath } : undefined,
                      }}
                    >
                      {t("registerInHere")}
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </form>

      {isBlueBirdTenant && isPreviewTenant && (
        <p
          className="text-center text-b2 font-light"
          style={{ color: theme === "dark" ? "#fff" : "#5D586C" }}
        >
          Saya menyetujui{" "}
          <span className="font-semibold underline">syarat ketentuan</span> &{" "}
          <span className="font-semibold underline">kebijakan privasi</span>{" "}
          goKampus.
        </p>
      )}

      {isPWA && (
        <p
          className="text-center text-b2 font-light"
          style={{ color: theme === "dark" ? "#fff" : "#5D586C" }}
        >
          Saya menyetujui{" "}
          <span className="font-semibold underline">syarat ketentuan</span> &{" "}
          <span className="font-semibold underline">kebijakan privasi</span>{" "}
          goKampus.
        </p>
      )}
    </div>
  );
};

export const FormRegister: React.FC<{
  currentLogin: any;
  handleUpdateStep: any;
  useFieldOrganization?: boolean;
  ip_address?: string;
  userAgent?: string;
}> = ({
  currentLogin,
  handleUpdateStep,
  useFieldOrganization,
  ip_address,
  userAgent,
}) => {
  const authSetLogin = useAuthSetLogin();
  const router = useRouter();
  const isB2B = useIsTenant() || useIsSubdomain();
  const referralCode =
    getCookie("ref_code_gk") && getCookie("ref_code_gk") !== "null"
      ? getCookie("ref_code_gk")
      : typeof window !== "undefined"
      ? new URLSearchParams(window.location.search).get("referral")
      : "";

  const useReferral = router.query.useReferral == "true" || referralCode;
  const { t } = useTranslation("common");
  const subDomain =
    isBrowser() && typeof window !== "undefined"
      ? getSubDomain(window.location.hostname)
      : null;
  const isSubdomainTenant = useIsSubdomain();
  const slug: string = CONST.IS_DEV ? "ares-gate" : "gate";
  const { email, name } = router.query;

  const RegisterSchema: any =
    subDomain !== slug
      ? z.object({
          name: z.string().min(3, { message: "Nama minimal 3 karakter" }),
          username: z.string().min(1, { message: "Masukkan email/no.hp kamu" }),
          password: z
            .string()
            .min(8, { message: "Kata sandi minimal 8 karakter" }),
          password_confirmation: z
            .string()
            .min(8, { message: "Kata sandi minimal 8 karakter" }),
        })
      : z.object({
          name: z.string().min(3, { message: "Nama minimal 3 karakter" }),
          username: z.string().min(1, { message: "Masukkan email/no.hp kamu" }),
          password: z
            .string()
            .min(8, { message: "Kata sandi minimal 8 karakter" }),
          password_confirmation: z
            .string()
            .min(8, { message: "Kata sandi minimal 8 karakter" }),
          referral: z.string().min(1, { message: t("passReferral") }),
        });

  type RegisData = z.infer<typeof RegisterSchema>;
  const hookRegisForm = useForm<RegisData>({
    resolver: zodResolver(RegisterSchema),
  });

  const { setValue } = hookRegisForm;

  useEffect(() => {
    if (email) setValue("username", email as string);
    if (name) setValue("name", name as string);
  }, [email, name, setValue]);

  const authRegisterApi = useAuthRegisterApi();
  const { handleSubmit } = hookRegisForm;
  const showToast = useToastStore((state) => state.showToast);

  const onRegist = (data: RegisData) => {
    let origin = "reguler";
    if (isB2B) {
      origin = "business";
    }

    trackEvent({
      event: "signup_a",
      property: {
        is_success: undefined,
      },
    });
    const referrals = !isB2B
      ? referralCode
      : data?.referral
      ? data?.referral
      : subDomain == "www"
      ? ""
      : subDomain;
    authRegisterApi.doFetch(
      ip_address
        ? {
            name: data.name,
            username: data.username,
            password: data.password,
            referral: referrals,
            ip_address,
            user_agent: userAgent,
            origin,
            password_confirmation: data.password_confirmation,
          }
        : {
            name: data.name,
            username: data.username,
            password: data.password,
            referral: referrals,
            user_agent: userAgent,
            origin,
            password_confirmation: data.password_confirmation,
          },
      {
        onSuccess(data: any) {
          if (isSubdomainTenant)
            authSetLogin(
              data.token,
              "username",
              "signup_success_a",
              null,
              "domain=gokampus.com"
            );
          authSetLogin(data.token, "username", "signup_success_a");
        },
        onError(err: any) {
          if (err instanceof authRegisterApi.fetcher.Error) {
            const error: any = err.getActualType();
            if ([409, 404, 403].includes(error?.status)) {
              hookRegisForm.setError("username", {
                message: error.data?.message,
              });
            }

            if (error?.status == 422) {
              Object.entries(error.data.errors).map(([key, value]) => {
                hookRegisForm.setError(key as any, {
                  message: value as any,
                });
              });
            } else if (error?.status == 429) {
              showToast({
                type: "danger",
                message: t("errorServer"),
              });
            }
          }
        },
      }
    );
  };

  const isGate =
    typeof window !== "undefined" && isGateURL(window.location.hostname);

  return (
    <div
      className={
        "w-full flex flex-col items-start justify-between sm-max:justify-start " +
        (isGate ? "flex flex-col justify-between h-full" : "")
      }
    >
      <div className="text-neutral-80">
        <p className="font-bold text-h4">{t("registering")}</p>
        <span className="font-light text-b2">{t("descriptionRegister")}</span>
      </div>

      <div className="w-full flex justify-center mt-24">
        {/* <AuthSocialButtonNew trackerId="signup_google_a" className="w-full" /> */}
      </div>

      <form
        onSubmit={handleSubmit(onRegist)}
        className="mt-24 min-h-[50vh] w-full"
      >
        {subDomain === slug && (
          <FieldText
            name="referral"
            label={t("passReferral")}
            placeholder={t("passReferralDesc")}
            validator={{
              hook: hookRegisForm,
            }}
          />
        )}
        <FieldText
          name="username"
          defaultValue={currentLogin.username ?? ""}
          label={t("emailTitle")}
          placeholder="Masukkan No. HP / Email"
          validator={{
            hook: hookRegisForm,
          }}
        />
        <FieldText
          name="name"
          label={t("nameTitle")}
          placeholder={t("nameDesc")}
          validator={{
            hook: hookRegisForm,
          }}
        />
        <FieldPassword
          name="password"
          label={t("passTitle")}
          placeholder={t("passDesc")}
          validator={{
            hook: hookRegisForm,
          }}
        />
        <FieldPassword
          name="password_confirmation"
          label={t("passConfirmTitle")}
          placeholder={t("passDesc")}
          validator={{
            hook: hookRegisForm,
          }}
        />

        <div>
          <div className="mb-16">
            <Button
              type="submit"
              size="large"
              isFull
              state={authRegisterApi.isLoading ? "loading" : "active"}
            >
              {t("register")}
            </Button>
          </div>

          <div className="text-b2 text-center font-light">
            {t("haveAccount")}{" "}
            <p
              className="cursor-pointer inline underline font-medium text-neutral-80 text-14"
              onClick={() => handleUpdateStep((n) => n - 1)}
            >
              {t("loginHere")}
            </p>
          </div>
        </div>
      </form>
    </div>
  );
};
