import { useState } from "react";
import * as z from "zod";
import { useForm } from "react-hook-form";
import Image from "next/image";
import { zodResolver } from "@hookform/resolvers/zod";

import FieldText from "components/Form/FieldText";
import Button from "components/Button";
import Modal from "components/Modal";
import { useAuthSendPasswordResetApi } from "./authApi";
import useTranslation from "next-translate/useTranslation";

export const ForgotSchema = z.object({
  email: z.string().email({ message: "Alamat Email tidak valid" }),
});

type FormData = z.infer<typeof ForgotSchema>;

export const AuthForgotPassword: React.FC<{
  isShow: boolean;
  onClose(): void;
  currentEmail?: string;
}> = ({ isShow, onClose, currentEmail }) => {
  const [isShowSuccess, showSuccess] = useState(false);

  const hookForm = useForm<FormData>({
    resolver: zodResolver(ForgotSchema),
  });

  const { handleSubmit, reset } = hookForm;

  const authSendPasswordReset = useAuthSendPasswordResetApi();

  /**
   * onSubmit
   */
  const onSubmit = (data: FormData) => {
    authSendPasswordReset.doFetch(
      {
        email: data.email,
      },
      {
        onSuccess(data) {
          showSuccess(true);
        },
        onError(err) {
          const error = err.getActualType();
          if ([409, 404, 400].includes(error?.status)) {
            hookForm.setError("email", {
              message: error.data?.message,
            });
          }
        },
      }
    );
    reset();
  };

  const { t } = useTranslation("common");

  return (
    <Modal
      title={isShowSuccess ? t("resetPassTitle") : t("resetPassword")}
      isShow={isShow}
      onClose={() => {
        hookForm.setValue("email", "");
        onClose();
      }}
    >
      {isShowSuccess ? (
        <>
          <div className="flex items-center pt-24">
            <Image
              src="/images/ProductIcon.svg"
              alt=""
              width={58}
              height={58}
            />
            <p className="text-b2 text-neutral-70 ml-16 font-light">
              {t("resetPassDesc")}
            </p>
          </div>
          <div className="mt-24">
            <Button
              size="large"
              isFull
              onClick={() => {
                showSuccess(false);
                hookForm.setValue("email", "");
                onClose();
              }}
            >
              {t("iUnderstand")}
            </Button>
          </div>
        </>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <FieldText
            name="email"
            label={t("emailTitle")}
            placeholder="name@mail.com"
            disabled={authSendPasswordReset.isLoading}
            defaultValue={currentEmail}
            validator={{
              hook: hookForm,
            }}
          />
          <div className="mt-18">
            <Button
              type="submit"
              size="large"
              state={authSendPasswordReset.isLoading ? "loading" : "active"}
              isFull
            >
              {t("submit")}
            </Button>
          </div>
        </form>
      )}
      <div />
    </Modal>
  );
};
