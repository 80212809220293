import { CONST } from "lib/const";
import Button from "components/Button";
import IconGoogleColor from "components/Icon/IconGoogleColor";
import { useAuthSocialApi } from "./authApi";
import { useAuthSetLogin } from "./authHooks";
import { trackEvent } from "lib/amplitude";
import Script from "next/script";
import { useToastStore } from "components/Toast/toastStore";
import useTranslation from "next-translate/useTranslation";

const googleClientID = CONST.GOOGLE_CLIENT_ID;

export const GoogleLoginButton: React.FC<{
  trackerId?: "signup_google_a" | "signin_google_a";
  title?: string;
}> = ({ trackerId, ...props }) => {
  const authSocial = useAuthSocialApi();
  const { t } = useTranslation("common");
  const authSetLogin = useAuthSetLogin();
  const toast = useToastStore();

  const handleClick = () => {
    //@ts-ignore
    const google = window.google;

    const client = (google as any)?.accounts?.oauth2.initTokenClient({
      client_id: googleClientID,
      scope: "profile email",
      callback: (response: any) => {
        const token = (response as any).access_token;

        if (token) {
          authSocial.doFetch(
            {
              provider: "google",
              token,
            },
            {
              onSuccess(data) {
                let trackIdByStatus =
                  data.new_user == true
                    ? "signup_success_a"
                    : "signin_success_a";

                authSetLogin(data.token, "google", trackIdByStatus);
              },
              onError(err) {
                console.log("err auth-social-login-new", err);

                toast.showToast({
                  type: "danger",
                  message: err?.data?.message,
                });
              },
            }
          );
        } else {
          toast.showToast({
            type: "danger",
            message: "Error",
          });
        }
      },
    });

    client.requestAccessToken();
  };

  return (
    <>
      {/* Provide an element for the button to render into */}

      <Script
        type="application/javascript"
        src="https://accounts.google.com/gsi/client"
        strategy="beforeInteractive"
      />

      <Button
        variant="secondary_gray"
        isFull
        size="large"
        onClick={() => {
          trackEvent({
            //@ts-ignore
            event: trackerId,
            property: { is_success: undefined },
          });
          handleClick();
        }}
      >
        <div className="flex items-center justify-center">
          <IconGoogleColor />
          <span className="text-16 leading-24 font-bold text-neutral-80 ml-10">
            {props.title ?? t("continueGoogle")}
          </span>
        </div>
      </Button>
    </>
  );
};

export const AuthSocialButtonNew: React.FC<{
  trackerId?: "signup_google_a" | "signin_google_a";
  title?: string;
  className?: string;
}> = (props) => {
  const authSocial = useAuthSocialApi();
  const { t } = useTranslation("common");
  const authSetLogin = useAuthSetLogin();

  return (
    <>
      <div className={"mb-28 " + (props.className ?? "")}>
        <div className="relative mt-0 mb-28 border-b border-neutral-30 flex items-center">
          <div className="absolute left-0 right-0 flex justify-center">
            <span className="text-16 leading-24 text-neutral-80 text-center px-20 bg-white">
              {t("or")}
            </span>
          </div>
        </div>

        <GoogleLoginButton {...props} />
      </div>
    </>
  );
};
