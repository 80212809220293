import React from "react";

type Props = {
  size?: number;
};

const IconGoogleColor: React.FC<Props> = ({ size = 20 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.39676 11.9133L3.75838 14.2999L1.42577 14.3442C0.720399 13.0309 0.345535 11.5646 0.333781 10.0728C0.322027 8.58103 0.673738 7.10896 1.35833 5.78461V5.78461L3.43628 6.16488L4.3456 8.23421C3.93634 9.43093 3.95443 10.7331 4.39676 11.918V11.9133Z"
        fill="#FBBB00"
      />
      <path
        d="M18.5065 8.28793C18.6135 8.85241 18.6672 9.42574 18.667 10.0003C18.6675 10.6437 18.6005 11.2853 18.467 11.9145C18.0258 13.9821 16.8854 15.8329 15.239 17.1532L12.6227 17.0155L12.2529 14.7036C13.3317 14.0715 14.1613 13.0869 14.6029 11.9145H9.70166V8.28793H18.5065Z"
        fill="#518EF8"
      />
      <path
        d="M15.2377 17.1486C13.6103 18.4582 11.5855 19.1703 9.4992 19.1667C7.84502 19.1682 6.22123 18.7208 4.79991 17.8718C3.37859 17.0229 2.2127 15.804 1.42578 14.3444L4.39677 11.9123C4.7881 12.9525 5.48623 13.8482 6.39807 14.4801C7.30991 15.112 8.39211 15.45 9.50037 15.4491C10.4676 15.4509 11.4178 15.1935 12.2528 14.7037L15.2377 17.1486Z"
        fill="#28B446"
      />
      <path
        d="M15.3517 2.94316L12.3819 5.37527C11.6864 4.94213 10.9027 4.67143 10.0889 4.58321C9.27501 4.49498 8.45179 4.59148 7.68013 4.86556C6.90847 5.13965 6.20813 5.5843 5.63094 6.16662C5.05375 6.74893 4.61449 7.454 4.34567 8.22964L1.3584 5.7847C1.99152 4.56783 2.88763 3.50821 3.98127 2.68326C5.07492 1.85831 6.33846 1.28887 7.67958 1.01654C9.02071 0.7442 10.4055 0.775853 11.7329 1.10918C13.0603 1.44251 14.2967 2.06909 15.3517 2.94316Z"
        fill="#F14336"
      />
    </svg>
  );
};

export default IconGoogleColor;
